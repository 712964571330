.hash-span {
  margin-top: -120px;
  padding-bottom: 120px;
  display: block;
}

.hero {
  min-height: calc(100vh - 70px);
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.green-gradient {
  background: rgb(43, 182, 115);
  background: linear-gradient(
    45deg,
    rgba(43, 182, 115, 0.5018382352941176) 0%,
    rgba(43, 182, 115, 1) 100%
  );
}

.feedback-card {
  transition: all 0.3s ease-out;
  background: transparent;
}

.feedback-card:hover {
  background: linear-gradient(
    45deg,
    rgba(43, 182, 115, 0.5018382352941176) 0%,
    rgba(43, 182, 115, 1) 100%
  );
}

.feedback-card:hover .quotes-img {
  filter: brightness(0);
}

.feedback-card:hover p {
  color: #fff;
}
